import "./styles.scss";
import React, { useState } from "react";
import RemoveIcon from "icons/exit.svg";

function Input({
  id,
  placeholder,
  type,
  name,
  onChange,
  required,
  file_error,
  setFileError,
  disabled,
}) {
  const [file_count, setFileCount] = useState(0);
  const limit = 5;

  const fileHandler = (e) => {
    let count = file_count;

    Array.from(e.target.files).forEach((file) => {
      if (count >= limit) {
        setFileError(true);
        return false;
      }

      onChange((prev) => [...prev, file]);
      count++;
    });

    setFileCount(count);
  };

  const removeHandler = () => {
    if (disabled) return false;

    onChange([]);
    setFileCount(0);
  };

  return (
    <div className="input-container">
      <div className={`input-component`}>
        <div className="input-wrapper">
          <label className={file_count > 0 ? "active" : ""}>
            {file_count > 0 ? `Wybrano (${file_count})` : placeholder}
            {required && <span>*</span>}
          </label>
          {file_count > 0 && <RemoveIcon onClick={removeHandler} />}
        </div>
        <input
          id={id}
          type={type}
          name={name}
          onChange={fileHandler}
          disabled={disabled}
          multiple
        />
      </div>
      {file_error && (
        <span className="error">
          Waga pliku nie może przekraczać 5 MB. Do jednego zgłoszenia mogą
          Państwo załączyć maksymalnie 5 plików
        </span>
      )}
    </div>
  );
}

export default Input;
