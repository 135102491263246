import * as React from "react";
import Form from "components/Form";
import SubpageHero from "components/SubpageHero";
import Layout from "components/Layout";
import Image1 from "images/contact/hero_1.png";
import Image2 from "images/contact/hero_2.png";

const ContactPage = () => {
  return (
    <Layout
      seo={{
        title: "Kontakt",
        description:
          "Jesteśmy otwarci na Twoje wizje i pomysły. Wierzymy, że dzięki naszemu podejściu stworzymy wspólnie inwestycje, które w 100% usatysfakcjonują każdą ze stron.",
      }}
    >
      <>
        <SubpageHero
          pageTitle="kontakt"
          header="Skontaktuj się z nami!"
          hint="Jesteśmy otwarci na Twoje wizje i pomysły. Wierzymy, że dzięki naszemu podejściu stworzymy wspólnie inwestycje, które w 100% usatysfakcjonują każdą ze stron."
          imageLeft={Image1}
          imageRight={Image2}
        />
        <Form
          header="Formularz kontaktowy"
          inputVariant={1}
          sideVariant={5}
          extraMargin
        />
      </>
    </Layout>
  );
};

export default ContactPage;
