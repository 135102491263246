import "./styles.scss";

import React from "react";
import { motion } from "framer-motion";
import { animation } from "utils/animation";

import HexagonItem from "components/HexagonItem";

import HexOneIcon from "icons/hex/one.svg";
import HexTwoIcon from "icons/hex/two.svg";
import HexThreeIcon from "icons/hex/three.svg";

const how_data = [
  {
    image: <HexOneIcon />,
    header: "Opisujesz usterkę",
    normalText: "Możesz także dołączyć zdjęcia.",
  },
  {
    image: <HexTwoIcon />,
    header: "Czekasz na kontakt",
    normalText:
      "Czekasz na kontakt ze strony naszego serwisanta, który skontaktuje się z Tobą telefonicznie, aby umówić termin naprawy.",
  },
  {
    image: <HexThreeIcon />,
    header: "Przyjęcie zgłoszenia",
    normalText:
      "Nasz serwisant zgłosi się w ciągu 48 godzin, aby zająć się naprawą.",
  },
];

function How() {
  return (
    <div className="how-section">
      <div className="container small">
        <motion.h2 className="center" {...animation.top}>
          Jak to działa?
        </motion.h2>
        <div className="items">
          {how_data.map((data, index) => (
            <HexagonItem key={index} {...data} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default How;
