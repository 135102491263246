import "./styles.scss";

import React from "react";
import Helmet from "react-helmet";
import { motion } from "framer-motion";

import { animation } from "utils/animation";

import HeroImage from "images/home/hero.png";

function Hero() {
  return (
    <div className="section-hero">
      <Helmet>
        <link rel="preload" as="image" href={HeroImage} />
      </Helmet>
      <motion.div
        className="container"
        initial="start"
        whileInView="end"
        {...animation.opacity}
        viewport={{ once: true }}
      >
        <div className="inner">
          <span>2G Investment</span>
          <h1>Ambitne projekty i nowoczesne realizacje</h1>
        </div>
        <img src={HeroImage} alt="" />
      </motion.div>
    </div>
  );
}

export default Hero;
