import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

import Carousel from "components/Carousel";
import Button from "components/Button";

function Project({ pageContext, posts }) {
  return (
    <div className="article-section">
      <div className="container">
        <div className="content">
          <div
            className="image"
            style={{
              backgroundImage: `url(${pageContext?.acfInvestments?.hero?.localFile?.publicURL})`,
            }}
          >
            {!!pageContext?.acfInvestments?.website && (
              <Link
                to={pageContext?.acfInvestments?.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                Zobacz stronę inwestycji
              </Link>
            )}
          </div>
          <div
            className="post_content"
            dangerouslySetInnerHTML={{
              __html: pageContext?.acfInvestments?.text,
            }}
          />
        </div>
        <Carousel items={posts} projectSection />
      </div>
    </div>
  );
}

export default Project;
