import React from "react";

import { Top, Footer, Seo, CookiesPopup } from "./components";

function Layout({ children, seo = {} }) {
  return (
    <>
      <Seo {...seo} />
      <Top />
      {children}
      <Footer />
      <CookiesPopup />
    </>
  );
}

export default Layout;
