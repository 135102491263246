import "./styles.scss";
import React, { useEffect } from "react";

import RecruitmentForm from "components/Form/components/inputVariants/RecruitmentForm";

import ExitIcon from "icons/exit.svg";

const Modal = ({ exit, workplace }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="modal-component">
      <div className="content">
        <ExitIcon className="exit" onClick={exit} />
        <div className="inner">
          <h2>Aplikuj na stanowisko</h2>
          <span>{workplace}</span>
        </div>
        <div className="component-form">
          <RecruitmentForm workplace={workplace} />
        </div>
      </div>
    </div>
  );
};

export default Modal;
