export const transition = {
  duration: 1,
  type: "tween",
};

export const container = {
  initial: "start",
  whileInView: "end",
  viewport: { once: true },
};

export const animation = {
  top: {
    transition,
    initial: { opacity: 0, y: 150 },
    whileInView: { opacity: 1, y: 0 },
    viewport: { once: true },
  },
  left: {
    transition,
    variants: {
      start: { opacity: 0, x: "-80%" },
      end: { opacity: 1, x: 0 },
    },
  },
  right: {
    transition,
    variants: {
      start: { opacity: 0, x: "80%" },
      end: { opacity: 1, x: 0 },
    },
  },
  opacity: {
    transition,
    variants: {
      start: { opacity: 0 },
      end: { opacity: 1 },
    },
  },
};
