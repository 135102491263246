import React from "react";

import SubpageHero from "components/SubpageHero";
import Layout from "components/Layout";

import { PrivacyPolicyContent } from "page_components/privacy-policy";

const FinancePage = () => {
  return (
    <Layout seo={{ title: "Polityka prywatności" }}>
      <div className="privacy-policy">
        <SubpageHero
          pageTitle="Polityka prywatności"
          header="Informacje podstawowe dotyczące przetwarzania danych osobowych"
          noExtraPadding
        />
        <PrivacyPolicyContent />
      </div>
    </Layout>
  );
};

export default FinancePage;
