import React from "react";

function VariantWithQuestion() {
  return (
    <div className="info VariantWithQuestion">
      <h3>Zrealizuj z nami swój projekt</h3>
      <p>
        Masz pytania? Chcesz poznać szczegóły współpracy? Zostaw swoje dane.
        Skontaktujemy się z Tobą.
      </p>
      <h3>Dane kontaktowe</h3>
      <span>
        <b>2G Investment Sp. z o.o.</b>
      </span>
      <div className="contact_group">
        <span>+48 609 599 999</span>
        <span>biuro@2ginvestment.pl</span>
      </div>
    </div>
  );
}

export default VariantWithQuestion;
