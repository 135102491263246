import "./styles.scss";

import React from "react";
import ProjectItem from "./components/ProjectItem";

function Projects({ data }) {
  const completed_projects = data.filter(
    ({ acfInvestments: { completed } }) => completed
  );

  const under_construction = data.filter(
    ({ acfInvestments: { completed } }) => !completed
  );

  return (
    <div className="projects-section">
      <div className="container">
        <div className="paper">
          <h2>Zrealizowane</h2>
          <div className="items">
            {completed_projects.map((data, index) => (
              <ProjectItem
                key={index}
                data={data}
                linkName="Więcej informacji"
              />
            ))}
          </div>
        </div>
        <div className="paper transparent">
          <h2>W trakcie budowy</h2>
          <div className="items">
            {under_construction.map((data, index) => (
              <ProjectItem
                key={index}
                data={data}
                linkName="Więcej informacji"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
