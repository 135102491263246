import "./styles.scss";

import React from "react";

import Carousel from "components/Carousel";

function Articles({ pageContext, posts }) {
  return (
    <div className="article-section">
      <div className="container">
        <div className="content">
          <div
            className="image"
            style={{
              backgroundImage: `url(${pageContext?.featuredImage?.node?.localFile?.publicURL})`,
            }}
          />
          <div
            className="post_content"
            dangerouslySetInnerHTML={{ __html: pageContext?.content }}
          />
        </div>
        <Carousel items={posts} articleSection />
      </div>
    </div>
  );
}

export default Articles;
