import "./styles.scss";

import React from "react";
import { motion } from "framer-motion";

function SubpageHero({
  pageTitle,
  header,
  hint,
  imageLeft,
  imageRight,
  noExtraPadding,
}) {
  return (
    <div
      className={`subpageHero-component ${
        noExtraPadding ? "no-extra-padding" : ""
      }`}
    >
      <div className="container">
        <motion.div
          className="inner"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ margin: "0px 0px -120px 0px", once: true }}
        >
          {pageTitle && <span>{pageTitle}</span>}
          <h1>{header}</h1>
          {hint && <p>{hint}</p>}
        </motion.div>
      </div>
      {imageLeft && <img src={imageLeft} className="left-img" alt="" />}
      {imageRight && <img src={imageRight} className="right-img" alt="" />}
    </div>
  );
}

export default SubpageHero;
