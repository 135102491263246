import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

import Logo from "images/logo_white.svg";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="content">
          <img src={Logo} alt="" />
          <div className="wrapper">
            <div className="nav">
              <div className="nav_group">
                <Link to="/o-nas/">O nas</Link>
                <Link to="/serwis/">Serwis</Link>
                <Link to="/realizacje/">Realizacje</Link>
                <Link to="/finansowanie/">Finansowanie</Link>
              </div>
              <div className="nav_group">
                <Link to="/kupimy-grunty/">Kupimy grunty</Link>
                <Link to="/blog/">Aktualności</Link>
                <Link to="/kariera/">Kariera</Link>
                <Link to="/kontakt/">Kontakt</Link>
              </div>
            </div>
            <div className="contact">
              <div className="contact_group">
                <strong>2G Investment Sp. z o.o.</strong>
                <span>ul. Łączna 39</span>
                <span>41-303 Dąbrowa Górnicza</span>
              </div>
              <div className="contact_group">
                <strong>
                  <a href="tel:+48609599999">+48 609 599 999</a>
                </strong>
                <span>
                  <strong>
                    <a href="mailto:biuro@2ginvestment.pl">
                      biuro@2ginvestment.pl
                    </a>
                  </strong>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <span>© 2022 2G Investment</span>
          <Link to="/polityka-prywatnosci/">Polityka prywatności</Link>
          <span>Projekt i wykonanie ADream.pl</span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
