import React from "react";

function VariantWithCompanyInfo() {
  return (
    <div className="info VariantWithCompanyInfo">
      <h3>Dane kontaktowe</h3>
      <div className="contact_group">
        <span>
          <b>2G Investment Sp. z o.o.</b>
        </span>
        <span>ul. Łączna 39</span>
        <span>41-303 Dąbrowa Górnicza</span>
      </div>
      <div className="contact_group">
        <span>
          <b>+48 609 599 999</b>
        </span>
        <span>
          <u>biuro@2ginvestment.pl</u>
        </span>
      </div>
    </div>
  );
}

export default VariantWithCompanyInfo;
