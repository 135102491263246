import "./styles.scss";

import React from "react";

import {
  VariantWithCompanyInfo,
  VariantWithImportant,
  VariantWithNotus,
  VariantWithPerson,
  VariantWithQuestion,
} from "./components/sideVariants";

import {
  StandardForm,
  FormWithLand,
  ServiceForm,
} from "./components/inputVariants";

function Form({ header, sideVariant, inputVariant, extraMargin }) {
  const renderSideVariant = () => {
    switch (sideVariant) {
      case 1:
        return <VariantWithQuestion />;
      case 2:
        return <VariantWithImportant />;
      case 3:
        return <VariantWithNotus />;
      case 4:
        // return <VariantWithPerson />;
        return null;
      case 5:
        return <VariantWithCompanyInfo />;
      default:
        return null;
    }
  };

  const renderInputVariant = () => {
    switch (inputVariant) {
      case 1:
        return <StandardForm />;
      case 2:
        return <FormWithLand />;
      case 3:
        return <ServiceForm />;
      default:
        return null;
    }
  };

  return (
    <div className={`component-form ${extraMargin ? "extraMargin" : ""}`}>
      <div className="container small">
        <h2 className="center">{header}</h2>
        {inputVariant === 2 && (
          <div className="header-info">
            <h3>Skontaktuj się z nami</h3>
            <p>
              Zadzwoń pod numer <strong>+48 609 599 999</strong> lub wyślij
              wiadomość na adres e-mail <strong>biuro@2ginvestment.pl</strong>
            </p>
            <p>Możesz także skorzystać z poniższego formularza.</p>
          </div>
        )}
        <div className="content">
          {renderSideVariant()}
          {renderInputVariant()}
        </div>
      </div>
    </div>
  );
}

export default Form;
