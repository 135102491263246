import "./styles.scss";

import React, { useEffect } from "react";
import { Link } from "gatsby";

import nav from "utils/nav";

import PhoneIcon from "icons/phone.svg";

function MobileMenu({ active }) {
  useEffect(() => {
    if (!active) return false;

    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [active]);

  return (
    <div className={`mobileMenu-component ${active ? "active" : ""}`}>
      <div className="menu">
        <nav>
          {nav.map(({ name, href }, index) => (
            <Link key={index} to={href} activeClassName="active">
              {name}
            </Link>
          ))}
        </nav>
      </div>
      <div className="bottom">
        <a href="tel:+48609599999" className="number">
          <PhoneIcon />
          <span>+48 609 599 999</span>
        </a>
      </div>
    </div>
  );
}

export default MobileMenu;
