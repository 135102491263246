import "./styles.scss";
import "react-multi-carousel/lib/styles.css";

import React from "react";
import { Link } from "gatsby";
import ReactMultiCarousel from "react-multi-carousel";
import { motion } from "framer-motion";

import NewsItem from "components/NewsItem";

import Arrow from "icons/arrow.svg";

const CustomRightArrow = ({ onClick }) => {
  return <Arrow className="arrow right" onClick={() => onClick()} />;
};

const CustomLeftArrow = ({ onClick }) => {
  return <Arrow className="arrow left" onClick={() => onClick()} />;
};

function Carousel({ items = [], articleSection, projectSection, moreButton }) {
  let responsive;

  if (articleSection) {
    responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1440 },
        items: 4,
      },
      tablet: {
        breakpoint: { max: 1440, min: 1090 },
        items: 3,
      },
      mobile: {
        breakpoint: { max: 1090, min: 430 },
        items: 2,
      },
    };
  } else {
    responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1120 },
        items: 3,
        partialVisibilityGutter: -15,
      },
      tablet: {
        breakpoint: { max: 1120, min: 860 },
        items: 2,
        partialVisibilityGutter: 30,
      },
      mobile: {
        breakpoint: { max: 860, min: 0 },
        items: 2,
        partialVisibilityGutter: 0,
      },
    };
  }

  return (
    <div className={`carousel-component ${articleSection ? "small" : ""}`}>
      <ReactMultiCarousel
        autoPlay={false}
        additionalTransfrom={0}
        containerClass="items"
        responsive={responsive}
        slidesToSlide={1}
        partialVisible={!articleSection && !projectSection}
        draggable
        swipeable
        arrows
        ssr
        shouldResetAutoplay={false}
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
      >
        {items.map((data) => (
          <NewsItem
            key={data.id}
            data={data}
            small={articleSection || projectSection}
            projectSection={projectSection}
          />
        ))}
      </ReactMultiCarousel>
      <div className="items mobile">
        {items.map((data) => (
          <NewsItem key={data.id} data={data} projectSection={projectSection} />
        ))}
      </div>
      <motion.div
        className={`btn ${moreButton ? "active" : ""}`}
        initial={{ opacity: 0, y: "50px" }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, type: "Tween" }}
        viewport={{ margin: "0px 0px -30px 0px", once: true }}
      >
        <Link to="/blog/">Zobacz wszystkie</Link>
      </motion.div>
    </div>
  );
}

export default Carousel;
