import "./styles.scss";

import React from "react";

import Pagination from "components/Pagination";
import ArticleItem from "./components/ArticleItem";

import Arrow from "icons/arrow_pagination.svg";

function Articles({ posts = [], pagination }) {
  return (
    <div className="articles-section">
      <div className="container">
        <div className="items">
          {posts.map((data, index) => (
            <ArticleItem key={index} data={data} linkName="Czytaj więcej" />
          ))}
        </div>
        <Pagination
          currentPage={pagination.currentPage}
          totalPages={pagination.numPages}
          arrow={<Arrow />}
        />
      </div>
    </div>
  );
}

export default Articles;
