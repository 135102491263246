import "./styles.scss";

import React from "react";

import LandHomesImage from "images/buy-land/land_homes.png";
import LandCommercialImage from "images/buy-land/land_commercial.png";
import LandTouristicImage from "images/buy-land/land_touristic.png";
import LandMallsImage from "images/buy-land/land_malls.png";

const buy_data = [
  {
    image: LandHomesImage,
    text: "Inwestycje mieszkaniowe",
  },
  {
    image: LandCommercialImage,
    text: "Powierzchnie komercyjne",
  },
  {
    image: LandTouristicImage,
    text: "Inwestycje turystyczne",
  },
  {
    image: LandMallsImage,
    text: "Powierzchnie handlowe",
  },
];

function Buy() {
  return (
    <div className="buy-section">
      <div className="container small">
        <h2 className="center">Nabywamy grunty pod</h2>
        <div className="items">
          {buy_data.map(({ image, text }, index) => (
            <div key={index} className="item">
              <img src={image} alt="" />
              <span>{text}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Buy;
