import React from "react";
import moment from "moment";
import "moment/locale/pl";

import Layout from "components/Layout";
import SubpageHero from "components/SubpageHero";
import Article from "page_components/article/Article";

const ArticlePage = ({ pageContext }) => {
  const pageData = pageContext.data;

  const date = moment(pageData.date).locale("pl").format("DD MMMM YYYY");

  const schema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: pageData.title,
    image: pageData?.featuredImage?.node?.localFile?.publicURL
      ? `https://2g-investment.pl/${pageData.featuredImage.node.localFile.publicURL}`
      : "",
    editor: `${pageData?.author?.node?.firstName || ""} ${
      pageData?.author?.node?.lastName || ""
    }`,
    genre: "",
    url: `https://2g-investment.pl/blog/${pageData.slug}`,
    datePublished: pageData.date,
    dateCreated: pageData.date,
    dateModified: pageData.date,
    description: pageData?.acfPost?.postExcerpt
      ? pageData.acfPost.postExcerpt
      : "",
    author: {
      "@type": "Person",
      name: `${pageData?.author?.node?.firstName || ""} ${
        pageData?.author?.node?.lastName || ""
      }`,
    },
  };

  return (
    <Layout
      seo={{
        title: pageData.seo.title ? pageData.seo.title : pageData.title,
        description: pageData.seo.metaDesc
          ? pageData.seo.metaDesc
          : pageData.acfPost.postExcerpt,
        image:
          pageData.featuredImage &&
          pageData.featuredImage.node.localFile.publicURL,
        schemaMarkup: schema,
      }}
    >
      <div className="article">
        <SubpageHero pageTitle={date} header={pageData.title} noExtraPadding />
        <Article pageContext={pageData} posts={pageContext.other_posts} />
      </div>
    </Layout>
  );
};

export default ArticlePage;
