import "./styles.scss";

import React from "react";
import { motion } from "framer-motion";
import { animation } from "utils/animation";
import HexagonItem from "components/HexagonItem";

import HexHomeIcon from "icons/hex/home.svg";
import HexMedalIcon from "icons/hex/medal.svg";
import HexStarIcon from "icons/hex/star.svg";
import HexGrowIcon from "icons/hex/grow.svg";
import HexEcoIcon from "icons/hex/eco.svg";

function Investment() {
  return (
    <div className="section-investment">
      <div className="container">
        <motion.h2 className="center" {...animation.top}>
          2G Investment
        </motion.h2>
        <div className="items">
          <HexagonItem image={<HexHomeIcon />} header="Nowoczesny design" />
          <HexagonItem image={<HexMedalIcon />} header="Wysoka jakość" />
          <HexagonItem image={<HexStarIcon />} header="Ambitne projekty" />
          <HexagonItem image={<HexGrowIcon />} header="Pełne zaangażowanie" />
          <HexagonItem image={<HexEcoIcon />} header="Troska o środowisko" />
        </div>
      </div>
    </div>
  );
}

export default Investment;
