import "./styles.scss";
import React from "react";

function Button({ label, small, htmlFor, type, onClick, success }) {
  return type === "file" ? (
    <label
      type="button"
      htmlFor={htmlFor}
      className={`button-component ${small ? "small" : ""}`}
    >
      {label}
    </label>
  ) : (
    <button
      type={type}
      htmlFor={htmlFor}
      className={`button-component ${small ? "small" : ""} ${
        success ? "success" : ""
      }`}
      onClick={onClick}
      disabled={success}
    >
      {success ? "WYSŁANO POMYŚLNIE" : label}
    </button>
  );
}

export default Button;
