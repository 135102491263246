import "./styles.scss";
import React from "react";
import { useField } from "formik";

function Select({ name, disabled }) {
  const [field] = useField({ name });

  return (
    <div className={`select-component ${field.value ? "selected" : ""}`}>
      <select {...field} disabled={disabled}>
        <option value="">Wybierz inwestycję</option>
        <option value="Inwestycja 1">Inwestycja 1</option>
        <option value="Inwestycja 2">Inwestycja 2</option>
      </select>
    </div>
  );
}

export default Select;
