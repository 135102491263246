import "./styles.scss";

import React from "react";
import { motion } from "framer-motion";
import { container, animation } from "utils/animation";

import AboutGirlImage from "images/about/about_girl.png";
import AboutCondoImage from "images/about/about_condo.png";

function About() {
  return (
    <div className="about-section">
      <div className="container small">
        <motion.div className="row" {...container}>
          <motion.img src={AboutGirlImage} alt="" {...animation.left} />
          <motion.div className="inner" {...animation.right}>
            <p>
              Jesteśmy rodzinną marką, którą połączyła w biznesie pasja do
              architektury i budownictwa. Cenimy dobry design i najlepszą
              jakość, dlatego to samo chcemy oferować naszym klientom.
            </p>
            <p>
              Jako marka 2G Investment działamy na rynku od 2016 roku.
              Zaczynaliśmy od pozyskiwania hali magazynowych, dziś stale
              poszerzamy portfolio naszych realizacji.
            </p>
          </motion.div>
        </motion.div>
        <motion.div
          className="row inverted"
          initial="start"
          whileInView="end"
          variants={animation.container}
          viewport={{ margin: "0px 0px -300px 0px", once: true }}
        >
          <motion.img src={AboutCondoImage} alt="" {...animation.right} />
          <motion.div className="inner" {...animation.left}>
            <p>
              Posiadamy know-how, które umożliwia nam kompleksowe wykonywanie
              ambitnych, wymagających projektów dla różnych branż.
            </p>
            <p>
              Głównie realizujemy inwestycje mieszkaniowe, handlowe i
              komercyjne, jednak pozostajemy otwarci na nowe wyzwania, które
              pozwolą nam spełniać się w naszej pasji.
            </p>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
}

export default About;
