import * as React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import SubpageHero from "components/SubpageHero";
import Articles from "page_components/news/Articles";

const BlogPage = ({ pageContext, data }) => {
  return (
    <Layout
      seo={{
        title: "Aktualności",
        description:
          "sprawdź co się u nas dzieje. W sprzedaży nowa inwestycja - Siewna 31 w Katowicach.",
      }}
    >
      <div className="articles">
        <SubpageHero
          pageTitle="Aktualności"
          header="Sprawdź co się u nas dzieje"
          noExtraPadding
        />
        <Articles posts={data?.allWpPost?.nodes} pagination={pageContext} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($limit: Int!, $skip: Int!) {
    allWpPost(limit: $limit, skip: $skip, sort: { fields: date, order: DESC }) {
      nodes {
        id
        slug
        title
        acfPost {
          postExcerpt
        }
        date
        featuredImage {
          node {
            id
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;

export default BlogPage;
