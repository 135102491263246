import "./styles.scss";

import React, { useState } from "react";
import ToggleItem from "./components/ToggleItem";
import Modal from "./components/Modal";

function Recruitment({ items = [] }) {
  const [modal, setModal] = useState(null);

  return (
    <div className="recruitment-section">
      <div className="container small">
        <h2 className="center">Aktualnie prowadzimy nabór na stanowiska</h2>
        <div className="items">
          {items.map(({ title, content }, index) => (
            <ToggleItem
              key={index}
              title={title}
              content={content}
              setModal={setModal}
            />
          ))}
        </div>
      </div>
      {modal && <Modal workplace={modal} exit={() => setModal(null)} />}
    </div>
  );
}

export default Recruitment;
