import "./styles.scss";

import React from "react";
import { Link } from "gatsby";
import moment from "moment";
import "moment/locale/pl";

function ArticleItem({ data, linkName, small }) {
  const date = data?.date
    ? moment(data.date).locale("pl").format("DD MMMM YYYY")
    : "";

  return (
    <Link to={`/blog/${data?.slug}/`} className="articleItem-component">
      <div className={`image ${small ? "small" : ""}`}>
        {data?.featuredImage?.node?.localFile?.publicURL && (
          <img src={data.featuredImage.node.localFile.publicURL} alt="" />
        )}
      </div>
      <div className="inner">
        <div className="header">
          {date && <span>{date}</span>}
          <h3>{data.title}</h3>
        </div>
        {data?.acfPost?.postExcerpt && (
          <p dangerouslySetInnerHTML={{ __html: data.acfPost.postExcerpt }} />
        )}
        <span className="read_more">{linkName}</span>
      </div>
    </Link>
  );
}

export default ArticleItem;
