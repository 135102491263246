import * as React from "react";
import Form from "components/Form";
import SubpageHero from "components/SubpageHero";
import About from "page_components/finance/About";
import Numbers from "page_components/finance/Numbers";
import Layout from "components/Layout";
import HeroLeft from "images/finance/hero_left.png";
import HeroRight from "images/finance/hero_right.png";

const FinancePage = () => {
  return (
    <Layout
      seo={{
        title: "Finansowanie",
        description:
          "Skorzystaj z oferty partnera 2G Investement, który pomoże Ci znaleźć najlepsze formy kredytowania.",
      }}
    >
      <div className="finance">
        <SubpageHero
          pageTitle="Finansowanie"
          header="Poszukujesz środków na sfinansowanie inwestycji?"
          hint="Skorzystaj z oferty naszego partnera, który pomoże Ci znaleźć najlepsze formy kredytowania."
          imageLeft={HeroLeft}
          imageRight={HeroRight}
        />
        <About />
        <Numbers />
        <Form header="Dane kontaktowe Notus" inputVariant={1} sideVariant={3} />
      </div>
    </Layout>
  );
};

export default FinancePage;
