import "./styles.scss";

import React from "react";
import { motion } from "framer-motion";
import { animation, container } from "utils/animation";

import HexIcon from "icons/hex.svg";

function Projects({ projects = [] }) {
  const filtered_items = projects.slice(-3);

  return (
    <div className="section-projects">
      <div className="container">
        <motion.div
          className="inner"
          whileInView="end"
          {...animation.top}
          initial={{ opacity: 0, y: 50 }}
        >
          <h2 className="center">Ambitne projekty to nasza specjalność</h2>
          <p>
            Nieustannie szukamy nowych wyzwań i najlepszych rozwiązań. Jesteśmy
            nastawieni na stały rozwój, dlatego z chęcią podejmiemy się
            wykonania nowatorskich, wymagających inwestycji.
          </p>
        </motion.div>
        <motion.h3 {...animation.top}>Nasze realizacje</motion.h3>
        <motion.div className="items" {...container}>
          {filtered_items.map(
            ({ title, slug, acfInvestments: { completed, image } }, index) => (
              <motion.a
                key={index}
                className="item"
                {...animation.top}
                href={`/realizacje/${slug}`}
              >
                <div className="content">
                  <div className="top">
                    <div
                      className="image"
                      style={{
                        backgroundImage: `url(${image?.localFile?.publicURL})`,
                      }}
                    />
                  </div>
                  <div className="bottom">
                    <span>{title}</span>
                    <div>
                      <HexIcon />
                      <span>
                        {completed ? "Zrealizowane" : "W trakcie budowy"}
                      </span>
                    </div>
                  </div>
                </div>
                <span className="float-text">{title}</span>
              </motion.a>
            )
          )}
        </motion.div>
      </div>
    </div>
  );
}

export default Projects;
