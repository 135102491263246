import "./styles.scss";

import React from "react";
import { useField } from "formik";

const Checkbox = ({ label, name, type = "checkbox", required, disabled }) => {
  const [field, meta] = useField({ name, type });

  return (
    <div className="checkbox-component">
      <input type={type} id={field.name} {...field} disabled={disabled} />
      <label htmlFor={field.name}>
        <span>
          {label}
          {required && <sup>*</sup>}
          {meta.touched && meta.error ? (
            <p className="error">{meta.error}</p>
          ) : null}
        </span>
      </label>
    </div>
  );
};

export default Checkbox;
