const nav = [
  {
    name: "O nas",
    href: "/o-nas/",
  },
  {
    name: "Aktualności",
    href: "/blog/",
  },
  {
    name: "Realizacje",
    href: "/realizacje/",
  },
  {
    name: "Finansowanie",
    href: "/finansowanie/",
  },
  {
    name: "Kupimy grunty",
    href: "/kupimy-grunty/",
  },
  // {
  //   name: "Serwis",
  //   href: "/serwis/",
  // },
  // {
  //   name: "Kariera",
  //   href: "/kariera/",
  // },
  {
    name: "Kontakt",
    href: "/kontakt/",
  },
];

export default nav;
