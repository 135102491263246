import "./styles.scss";

import React from "react";
import { motion } from "framer-motion";
import { animation } from "utils/animation";
import HexagonItem from "components/HexagonItem";

import HexMedalIcon from "icons/hex/medal.svg";
import HexStarIcon from "icons/hex/star.svg";
import HexGrowIcon from "icons/hex/grow.svg";

const how_data = [
  {
    image: <HexMedalIcon />,
    header: "Jakość",
    normalText:
      "Wkładamy 100% naszej uwagi, aby dostarczyć klientom maksymalnie satysfakcjonujące wykonanie.",
  },
  {
    image: <HexStarIcon />,
    header: "Pasja",
    normalText:
      "Jakość to bezpieczeństwo. Do projektów podchodzimy odpowiedzialnie i dbamy o wysoką jakość naszych usług.",
  },
  {
    image: <HexGrowIcon />,
    header: "Zaangażowanie",
    normalText:
      "Działamy z pasją, dlatego możesz liczyć na nasze pełne zaangażowanie i wsparcie na każdym z etapów realizacji.",
  },
];

function How() {
  return (
    <div className="value-section">
      <div className="container small">
        <motion.h2 className="center" {...animation.top}>
          Nasze wartości
        </motion.h2>
        <div className="items">
          {how_data.map((data, index) => (
            <HexagonItem key={index} {...data} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default How;
