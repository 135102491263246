import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

function Pagination({ arrow, currentPage, totalPages }) {
  return (
    <ul className="pagination">
      {currentPage > 1 && (
        <li className="arrow">
          <Link
            to={
              currentPage - 1 === 1 ? `/blog/` : `/blog/page/${currentPage - 1}`
            }
          >
            {arrow}
          </Link>
        </li>
      )}
      {currentPage - 2 > 0 && (
        <li>
          <Link
            to={
              currentPage - 2 === 1
                ? `/blog/`
                : `/blog/page/${currentPage - 2}/`
            }
          >
            {currentPage - 2}
          </Link>
        </li>
      )}
      {currentPage - 1 > 0 && (
        <li>
          <Link
            to={
              currentPage - 1 === 1
                ? `/blog/`
                : `/blog/page/${currentPage - 1}/`
            }
          >
            {currentPage - 1}
          </Link>
        </li>
      )}
      <li className="active">
        <span>{currentPage}</span>
      </li>
      {currentPage + 1 <= totalPages && (
        <li>
          <Link
            to={
              currentPage + 1 === 1
                ? `/blog/`
                : `/blog/page/${currentPage + 1}/`
            }
          >
            {currentPage + 1}
          </Link>
        </li>
      )}
      {currentPage + 2 <= totalPages && (
        <li>
          <Link
            to={
              currentPage + 2 === 1
                ? `/blog/`
                : `/blog/page/${currentPage + 2}/`
            }
          >
            {currentPage + 2}
          </Link>
        </li>
      )}
      {currentPage < totalPages && (
        <li className="arrow">
          <Link to={`/blog/page/${currentPage + 1}`}>{arrow}</Link>
        </li>
      )}
    </ul>
  );
}

export default Pagination;
