import * as React from "react";
import { graphql } from "gatsby";

import Form from "components/Form";
import Layout from "components/Layout";
import SubpageHero from "components/SubpageHero";
import Projects from "page_components/realizations/Projects";

const realizationsPage = ({ data }) => {
  return (
    <Layout
      seo={{
        title: "Realizacje",
        description: "Prowadzone oraz zrealizowane inwestycje 2G Investment",
      }}
    >
      <div className="realizations">
        <SubpageHero
          pageTitle="Realizacje"
          header="Sprawdź nasze projekty"
          noExtraPadding
        />
        <Projects data={data?.allWpInvestment?.nodes} />
        <Form header="Formularz kontaktowy" inputVariant={1} sideVariant={1} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allWpInvestment {
      nodes {
        title
        slug
        acfInvestments {
          completed
          text
          image {
            id
            localFile {
              publicURL
            }
          }
          excerpt
        }
      }
    }
  }
`;

export default realizationsPage;
