import "./styles.scss";

import React from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import { container, animation } from "utils/animation";

import Image from "images/about/plans.png";

function Plans() {
  return (
    <div className="plans-section">
      <div className="container small">
        <motion.h2 className="center" {...animation.top}>
          Plany na przyszłość
        </motion.h2>
        <motion.div className="row" {...container}>
          <motion.img src={Image} alt="" {...animation.left} />
          <motion.div className="inner" {...animation.right}>
            <p>
              Chęć rozwoju motywuje nas do poszukiwania nowych rozwiązań. Stale
              podnosimy jakość naszych realizacji i otwieramy się na nowe
              branże.
            </p>
            <p>
              Naszym celem jest sprawienie, aby realizowane projekty dawały
              pozytywny impact społeczny, środowiskowy i ekonomiczny.{" "}
            </p>
            <p>
              W najbliższej przyszłości chcemy skupić się na projektach, które
              pozwolą nam poszerzyć portfolio naszych realizacji o ciekawe,
              nowatorskie realizacje.
            </p>
            <p>
              Poszukujemy gruntów pod inwestycje - szczegóły znajdziesz
              <Link to="/kupimy-grunty/">tutaj</Link>.
            </p>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
}

export default Plans;
