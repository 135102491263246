import * as React from "react";

import Form from "components/Form";
import SubpageHero from "components/SubpageHero";
import Layout from "components/Layout";
import Buy from "page_components/buy-land/Buy";
import How from "page_components/buy-land/How";

import Image1 from "images/buy-land/hero_left.png";
import Image2 from "images/buy-land/hero_right.png";

const LandPage = () => {
  return (
    <Layout
      seo={{
        title: "Kupimy Grunty",
        description:
          "Posiadasz grunty na sprzedaż? 2G Investment poszukuje terenów budowlanych o różnej powierzchni i w różnej lokalizacji.",
      }}
    >
      <div className="buy-land">
        <SubpageHero
          pageTitle="Kupimy Grunty"
          header="Posiadasz grunty na sprzedaż?"
          hint="Poszukujemy terenów budowlanych o różnej powierzchni i w różnej lokalizacji."
          imageLeft={Image1}
          imageRight={Image2}
        />
        <Buy />
        <How />
        <Form
          header="Porozmawiajmy o szczegółach"
          inputVariant={2}
          sideVariant={4}
        />
      </div>
    </Layout>
  );
};

export default LandPage;
