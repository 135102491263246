import "./styles.scss";

import React, { useState, useEffect } from "react";

import Button from "components/Button";

import { useCookies } from "react-cookie";

const CookiesPopup = () => {
  const [{ cookie_accepted }, setCookie] = useCookies(["cookie_accepted"]);
  const [show_cookie, setShowCookie] = useState(false);

  useEffect(() => {
    setShowCookie(!cookie_accepted);
  }, [cookie_accepted]);

  if (!!!show_cookie) return null;

  return (
    <div className="cookies-popup">
      <p>
        Aby zoptymalizować naszą stronę internetową i stale ją ulepszać, używamy{" "}
        <a
          href="/polityka-prywatnosci/"
          target="_blank"
          rel="noopener noreferrer"
        >
          plików cookie
        </a>
        .
      </p>
      <Button
        label="Akceptuję"
        small
        onClick={() => setCookie("cookie_accepted", "true", { path: "/" })}
      />
    </div>
  );
};

export default CookiesPopup;
