import "./styles.scss";

import React from "react";
import { motion } from "framer-motion";
import { container, animation } from "utils/animation";

import AboutImage from "images/home/about.png";

function About() {
  return (
    <div className="section-about">
      <motion.div
        className="container small"
        {...container}
        viewport={{ margin: "0px 0px -300px 0px", once: true }}
      >
        <motion.div className="inner" {...animation.left}>
          <h2>O nas</h2>
          <p>
            Realizujemy inwestycje mieszkaniowe, komercyjne i handlowe.
            Architektura i nieruchomości to nasza pasja, dlatego nasi klienci
            zawsze otrzymują 100% naszego zaangażowania. Nowoczesne,
            funkcjonalne realizacje 2G Investment to gwarancja dobrego designu i
            wysokiej jakości.
          </p>
        </motion.div>
        <motion.img alt="" src={AboutImage} {...animation.right} />
      </motion.div>
    </div>
  );
}

export default About;
