import * as React from "react";
import { graphql } from "gatsby";

import SubpageHero from "components/SubpageHero";
import Layout from "components/Layout";
import Recruitment from "page_components/carrier/Recruitment";

import HeroLeft from "images/carrier/hero_left.png";
import HeroRight from "images/carrier/hero_right.png";

const CareerPage = ({ data }) => {
  return (
    <Layout seo={{ title: "Kariera" }}>
      <div className="recruitment">
        <SubpageHero
          pageTitle="Kariera"
          header="Jeśli jesteś pasjonatem, 
          zaaplikuj do nas!"
          hint="Jeśli, tak jak my, jesteś pasjonatem projektowania i budowania nieruchomości i poszukujesz pracy, w której będziesz mógł realizować się zawodowo zaaplikuj do nas!"
          imageLeft={HeroLeft}
          imageRight={HeroRight}
        />
        <Recruitment items={data?.wpPage?.acfCarrier?.workplace} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDo0Nw==" }) {
      acfCarrier {
        workplace {
          content
          title
        }
      }
    }
  }
`;

export default CareerPage;
