import React from "react";

function VariantWithNotus() {
  return (
    <div className="info VariantWithNotus">
      <h3>NOTUS Finanse S.A.</h3>
      <div className="contact_group">
        <span>ul. Puławska 2</span>
        <span>02-566 Warszawa</span>
      </div>
      <h3>Infolinia</h3>
      <div className="contact_group">
        <span>+48 603 804 805</span>
        <span>kontakt@notus.pl</span>
      </div>
      <div className="contact_group">
        <span>poniedziałek – piątek</span>
        <span>od 9:00 do 19:00</span>
      </div>
      <div className="contact_group">
        <span>+48 22 59 63 963</span>
        <span>+48 22 59 63 990</span>
      </div>
      <a href="https://notus.pl">notus.pl</a>
    </div>
  );
}

export default VariantWithNotus;
