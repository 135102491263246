import React from "react";

function VariantWithImportant() {
  return (
    <div className="info VariantWithImportant">
      <h3>Ważne informacje</h3>
      <p>
        1. Prosimy, aby podczas dokonywania zgłoszeń reklamacyjnych przez
        formularz WWW usterki były przesyłane pojedynczo, używając oddzielnego
        formularza jeżeli dotyczą różnych typów zgłoszeń.
      </p>
      <p>
        2. Zgłoszenie powinno zawierać dokładny opis wad oraz w miarę możliwości
        zdjęcia poglądowe.
      </p>
      <p>
        3. Po dokonaniu zgłoszenia otrzymasz potwierdzenie na adres e-mail
        podany w zgłoszeniu
      </p>
    </div>
  );
}

export default VariantWithImportant;
