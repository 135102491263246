/* eslint-disable jsx-a11y/no-autofocus */

import "./styles.scss";
import React, { useState } from "react";
import { useField } from "formik";

function Input({ placeholder, type, name, required, multiline, disabled }) {
	const [focus, setFocus] = useState(false);
	const [field, meta] = useField({ name, type });

	return (
		<div className="input-container">
			<div
				className={`input-component ${multiline ? "multiline" : ""}`}
				onMouseUp={() => setFocus(true)}
				aria-hidden="true"
			>
				{focus || field.value.length > 0 ? (
					multiline ? (
						<textarea
							{...field}
							autoFocus
							onBlur={() => setFocus(false)}
							name={name}
							disabled={disabled}
						></textarea>
					) : (
						<input
							{...field}
							autoFocus
							onBlur={() => setFocus(false)}
							type={type}
							name={name}
							disabled={disabled}
						/>
					)
				) : (
					<label>
						{placeholder}
						{required && <span>*</span>}
					</label>
				)}
			</div>
			{meta.touched && meta.error ? (
				<span className="error">{meta.error}</span>
			) : null}
		</div>
	);
}

export default Input;
