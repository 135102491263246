import "./styles.scss";

import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { animation } from "utils/animation";
import CountUp from "react-countup";

const numbers_data = [
  {
    number: "16",
    text: "Banków, których oferty możesz porównać",
  },
  {
    number: "18",
    text: "lat doświadczenia",
  },
  {
    number: "76",
    text: "oddziałów w miastach na terenie całego kraju",
  },
  {
    number: "1680",
    text: "ekspertów w zespole",
  },
  {
    number: 400000,
    text: "zadowolonych klientów",
  },
];

function Numbers() {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <div className="numbers-section">
      <div className="container">
        <motion.h2 className="center" {...animation.top}>
          Notus w liczbach
        </motion.h2>
        <motion.div ref={ref} className="items" {...animation.top}>
          {numbers_data.map(({ number, text }, index) => (
            <div key={index} className="item">
              <CountUp start={0} separator=" " end={inView ? number : 0} />
              <span>{text}</span>
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
}

export default Numbers;
