import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";

import {
  Hero,
  About,
  Investment,
  OurInvestment,
  Projects,
  News,
} from "page_components/home";

import Form from "components/Form";

const IndexPage = ({ data }) => {
  return (
    <Layout
      seo={{
        title: "Strona główna",
        description:
          "ambitne projekty i nowoczesne realizacje. Jesteśmy rodzinną marką, którą połączyła w biznesie pasja do architektury i budownictwa. Cenimy dobry design i najlepszą jakość, dlatego to samo chcemy oferować naszym klientom.",
      }}
    >
      <div className="home">
        <Hero />
        <About />
        <Investment />
        <OurInvestment />
        <Projects projects={data?.allWpInvestment?.nodes} />
        <News posts={data?.allWpPost?.nodes} />
        <Form header="Formularz kontaktowy" sideVariant={1} inputVariant={1} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allWpPost(limit: 6, sort: { fields: date, order: DESC }) {
      nodes {
        id
        slug
        title
        acfPost {
          postExcerpt
        }
        date
        featuredImage {
          node {
            id
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allWpInvestment {
      nodes {
        title
        slug
        acfInvestments {
          completed
          text
          image {
            id
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
