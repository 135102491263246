import "./styles.scss";

import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

import MobileMenu from "components/MobileMenu";
import nav from "utils/nav";

import LogoImage from "images/logo_image.svg";
import LogoText from "images/logo_text.svg";
import PhoneIcon from "icons/phone.svg";
import HamburgerIcon from "icons/hamburger.svg";
import ExitIcon from "icons/exit.svg";

function Top() {
  const [is_mobile_menu_active, setMobileMenuActive] = useState(false);
  const [transparent, setTransparent] = useState(true);
  const { pathname } = useLocation();

  const handleScroll = useCallback(
    (e) => {
      if (e.currentTarget.scrollY > 0) {
        if (!transparent) return false;
        setTransparent(false);
      } else {
        setTransparent(true);
      }
    },
    [transparent]
  );

  useEffect(() => {
    if (window.scrollY > 0) {
      setTransparent(false);
    }

    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [handleScroll]);

  return (
    <div
      className={`section-top ${!transparent ? "withBackground" : undefined}`}
    >
      <div className="container">
        <Link className="logo" to="/">
          <img src={LogoImage} alt="" />
          <img src={LogoText} alt="" />
        </Link>
        <div className="menu">
          <nav>
            {nav.map(({ name, href }, index) => (
              <Link key={index} to={href} activeClassName="active">
                {name}
              </Link>
            ))}
          </nav>
          <a
            href="tel:+48609599999"
            className={`number ${pathname !== "/" ? "active" : ""}`}
          >
            <PhoneIcon />
            <span>+48 609 599 999</span>
          </a>
        </div>
        {!is_mobile_menu_active ? (
          <HamburgerIcon
            className="mobile_menu"
            onClick={() => setMobileMenuActive(true)}
          />
        ) : (
          <ExitIcon
            className="mobile_menu exit"
            onClick={() => setMobileMenuActive(false)}
          />
        )}
      </div>
      <MobileMenu active={is_mobile_menu_active} />
    </div>
  );
}

export default Top;
