import "./styles.scss";

import React from "react";
import { Link } from "gatsby";
import moment from "moment";
import "moment/locale/pl";

function ProjectItem({ data, linkName }) {
  const date = data?.date
    ? moment(data.date).locale("pl").format("DD MMMM YYYY")
    : "";

  return (
    <Link to={`/realizacje/${data?.slug}/`} className="projectItem-component">
      <div className="image">
        {data?.acfInvestments?.image?.localFile?.publicURL && (
          <img src={data.acfInvestments.image.localFile.publicURL} alt="" />
        )}
      </div>
      <div className="inner">
        <div className="header">
          {date && <span>{date}</span>}
          <h3>{data.title}</h3>
        </div>
        {data?.acfInvestments?.excerpt && (
          <p
            dangerouslySetInnerHTML={{ __html: data.acfInvestments.excerpt }}
          />
        )}
        <span className="read_more">{linkName}</span>
      </div>
    </Link>
  );
}

export default ProjectItem;
