import React from "react";
import "moment/locale/pl";

import Layout from "components/Layout";
import SubpageHero from "components/SubpageHero";
import Project from "page_components/realizations/Project";

const InvestmentPage = ({ pageContext }) => {
  const pageData = pageContext.data;

  // const date = moment(pageData.date).locale("pl").format("DD MMMM YYYY");

  return (
    <Layout
      seo={{
        title: pageData.seo.title ? pageData.seo.title : pageData.title,
        description: pageData.seo.metaDesc
          ? pageData.seo.metaDesc
          : pageData.acfInvestments.excerpt,
        image:
          pageData?.acfInvestments?.image?.localFile?.publicURL &&
          pageData.acfInvestments.image.localFile.publicURL,
      }}
    >
      <div className="article">
        <SubpageHero
          pageTitle={
            pageData.acfInvestments.completed
              ? "Zrealizowane"
              : "W trakcie budowy"
          }
          header={pageData.title}
          noExtraPadding
        />
        <Project pageContext={pageData} posts={pageContext.other_posts} />
      </div>
    </Layout>
  );
};

export default InvestmentPage;
