import "./styles.scss";

import React from "react";
import { motion } from "framer-motion";
import { container, animation, transition } from "utils/animation";

import OurInvestmentHomesImage from "images/home/ourinvestment_homes.png";
import OurInvestmentMallsImage from "images/home/ourinvestment_malls.png";
import OurInvestmentCommercialImage from "images/home/ourinvestment_commercial.png";

const our_investment_data = [
  {
    image: OurInvestmentHomesImage,
    title: "Inwestycje mieszkaniowe",
  },
  {
    image: OurInvestmentMallsImage,
    title: "Inwestycje handlowe",
  },
  {
    image: OurInvestmentCommercialImage,
    title: "Inwestycje komercyjne",
  },
];

function OurInvestment() {
  return (
    <div className="section-our-investment">
      <motion.div
        className="container"
        {...container}
        viewport={{ margin: "0px 0px -300px 0px", once: true }}
      >
        <motion.h2 className="center" {...animation.opacity}>
          Nasze inwestycje
        </motion.h2>
        <div className="items">
          {our_investment_data.map(({ image, title }, index) => (
            <motion.div
              key={index}
              className="item"
              style={{ backgroundImage: `url(${image})` }}
              variants={
                index === 0
                  ? animation.left.variants
                  : index === 1
                  ? animation.opacity.variants
                  : animation.right.variants
              }
              transition={transition}
            >
              <div className="content">
                <span>{title}</span>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
}

export default OurInvestment;
