import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import Button from "components/Button";
import Input from "components/Input";
import Checkbox from "components/Checkbox";

function StandardForm() {
  const onSubmit = async (
    { name, phone, email, message, legal1, legal2 },
    { setSubmitting }
  ) => {
    try {
      let formData = new FormData();

      formData.set("fullName", name);
      formData.set("phone", phone);
      formData.set("email", email);
      formData.set("message", message);
      formData.set("legal1", legal1);
      formData.set("legal2", legal2);
      await axios({
        method: "post",
        url: "https://wp.2ginvestment.pl/wp-json/contact-form-7/v1/contact-forms/8/feedback/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      setSubmitting(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        phone: "",
        email: "",
        message: "",
        legal1: false,
        legal2: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Nieprawidłowy email")
          .required("E-mail jest wymagany"),
        phone: Yup.number("Nieprawidłowy numer telefonu")
          .typeError("Nieprawidłowy numer telefonu")
          .required("Numer telefonu jest wymagany"),
        name: Yup.string().required("Imię i nazwisko jest wymagane"),
        message: Yup.string().required("Wiadomość jest wymagana"),
        legal1: Yup.bool().oneOf([true], "Pole wymagane"),
      })}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form noValidate onSubmit={handleSubmit}>
          <div className="input_group">
            <Input
              type="text"
              name="name"
              placeholder="Imię"
              required
              disabled={isSubmitting}
            />
            <Input
              type="text"
              name="phone"
              placeholder="Numer telefonu"
              required
              disabled={isSubmitting}
            />
          </div>
          <Input
            type="text"
            name="email"
            placeholder="E-mail"
            required
            disabled={isSubmitting}
          />
          <Input
            type="text"
            name="message"
            placeholder="Wiadomości"
            required
            multiline
            disabled={isSubmitting}
          />
          <Checkbox
            label={
              "Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych przez 2G Investment Sp. z o.o. NIP: 6292475924, REGON:365195736, KRS: 0000632801, w celu marketingu bezpośredniego dotyczącego własnych produktów i usług. Obowiązek informacyjny zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych (RODO) z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016): informacje o przetwarzaniu przez nas danych, jak również o prawach osób, których dane dotyczą znajdują się w naszej polityce prywatności polityce prywatności"
            }
            name="legal1"
            required
            disabled={isSubmitting}
          />
          <Checkbox
            label={
              "Wyrażam zgodę na przesyłanie na podany przeze mnie adres poczty elektronicznej informacji handlowych zgodnie z przepisami ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną przyjmując jednocześnie do wiadomości, że niniejsza zgoda może być odwołana w każdym czasie."
            }
            name="legal2"
            disabled={isSubmitting}
          />
          <span className="required-info">
            <sup>*</sup>pola wymagane
          </span>
          <div className="submit-btn">
            <Button label="Wyślij" type="submit" success={isSubmitting} />
          </div>
        </form>
      )}
    </Formik>
  );
}

export default StandardForm;
