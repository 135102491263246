import "./styles.scss";

import React from "react";
import Collapsible from "react-collapsible";

import Button from "components/Button";

import Arrow from "icons/toggle_arrow.svg";

const ToggleHeader = ({ text }) => {
  return (
    <>
      <span>{text}</span>
      <Arrow />
    </>
  );
};

function ToggleItem({ title, content, setModal }) {
  return (
    <Collapsible trigger={<ToggleHeader text={title} />} transitionTime={200}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
      <Button label="Aplikuj" onClick={() => setModal(title)} />
    </Collapsible>
  );
}

export default ToggleItem;
