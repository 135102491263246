import "./styles.scss";

import React from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import { animation } from "utils/animation";
import moment from "moment";

import HexIcon from "icons/hex.svg";

function NewsItem({ data, small, projectSection }) {
  const date = data?.date ? moment(data.date).format("DD.MM.YYYY") : "";

  return (
    <Link
      to={`/${projectSection ? "realizacje" : "blog"}/${data.slug}/`}
      className={`news-component ${small ? "small" : ""}`}
    >
      {projectSection
        ? data?.acfInvestments?.image?.localFile?.publicURL && (
            <motion.div
              className="image"
              style={{
                backgroundImage: `url(${data.acfInvestments.image.localFile.publicURL})`,
              }}
            />
          )
        : data?.featuredImage?.node?.localFile?.publicURL && (
            <motion.div
              className="image"
              style={{
                backgroundImage: `url(${data.featuredImage.node.localFile.publicURL})`,
              }}
            />
          )}
      <motion.div
        className="inner"
        {...animation.top}
        initial={{ opacity: 0, y: 50 }}
      >
        {!projectSection && (
          <div className="date">
            <HexIcon />
            <span>{date}</span>
          </div>
        )}
        <h3>{data?.title}</h3>
        {projectSection
          ? data?.acfInvestments?.excerpt && (
              <p
                dangerouslySetInnerHTML={{
                  __html: data.acfInvestments.excerpt,
                }}
              />
            )
          : data?.acfPost?.postExcerpt && (
              <p
                dangerouslySetInnerHTML={{ __html: data.acfPost.postExcerpt }}
              />
            )}

        <span className="read_more">Czytaj więcej</span>
      </motion.div>
    </Link>
  );
}

export default NewsItem;
