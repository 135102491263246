import "./styles.scss";

import React from "react";
import { motion } from "framer-motion";
import { container, animation } from "utils/animation";

import HexagonItem from "components/HexagonItem";

import NotusLogo from "images/notus_logo.svg";
import HexWalletIcon from "icons/hex/wallet.svg";
import HexDocumentIcon from "icons/hex/document.svg";
import HexContactIcon from "icons/hex/contact.svg";

const about_data = [
  {
    image: <HexWalletIcon />,
    header: "Brak dodatkowych kosztów",
    normalText: "Usługi NOTUS są bezpłatne",
  },
  {
    image: <HexDocumentIcon />,
    header: "Pomoc w komplementacji dokumentów",
    normalText:
      "Pomoc w skompletowniu dokumentów oraz przygotowaniu wniosków kredytowych",
  },
  {
    image: <HexContactIcon />,
    header: "Obsługa online",
    normalText: "NOTUS zapewnia obsługę bez wychodzenia z domu",
  },
];

function About() {
  return (
    <div className="about-section">
      <div className="container small">
        <motion.div
          className="about"
          {...container}
          viewport={{ margin: "0px 0px -120px 0px", once: true }}
        >
          <motion.img src={NotusLogo} alt="" {...animation.left} />
          <motion.div className="info" {...animation.right}>
            <p>
              <b>NOTUS Finanse</b> jest jedną z największych i najwyżej
              ocenianych firm pośrednictwa finansowego w Polsce. Siłą firmy jest
              m.in. bardzo wysoka jakość obsługi klienta, skuteczność,
              profesjonalizm oraz bogata oferta produktów hipotecznych i
              ubezpieczeniowych.
            </p>
            <p>
              Wspiera w wyborze najlepszej oferty z szerokiej palety produktów
              finansowych, z uwzględnieniem przede wszystkim Twoich potrzeb i
              oczekiwań.
            </p>
          </motion.div>
        </motion.div>
        <div className="why">
          <motion.h2 className="center" {...animation.top}>
            Dlaczego Notus?
          </motion.h2>
          <div className="items">
            {about_data.map((data, index) => (
              <HexagonItem key={index} {...data} variant="horizontal" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
