import "./styles.scss";

import React from "react";
import { motion } from "framer-motion";
import { animation } from "utils/animation";

function HexagonItem({ image, header, normalText, variant, connection }) {
  return (
    <motion.div
      className={`hexagon-component ${
        connection ? "connection" : ""
      } ${variant}`}
      {...animation.top}
    >
      <div className="top">
        <div className="image">
          {image}
          {connection && <span className="connect"></span>}
        </div>
      </div>
      <div className="bottom">
        {header && <span className="header">{header}</span>}
        {normalText && <span className="normalText">{normalText}</span>}
      </div>
    </motion.div>
  );
}

export default HexagonItem;
