import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import Button from "components/Button";
import Input from "components/Input";
import UploadInput from "components/UploadInput";
import Select from "components/Select";
import Checkbox from "components/Checkbox";

function ServiceForm() {
  const [files, setFiles] = useState([]);
  const [file_error, setFileError] = useState(false);
  const [is_submit, setIsSubmit] = useState(false);

  const onSubmit = async ({
    name,
    phone,
    email,
    number,
    invest,
    message,
    legal1,
    legal2,
  }) => {
    try {
      if (is_submit) return false;

      let formData = new FormData();
      formData.set("fullName", name);
      formData.set("phone", phone);
      formData.set("email", email);
      formData.set("houseNumber", number);
      formData.set("investment", invest);
      formData.set("message", message);
      formData.set("legal1", legal1);
      formData.set("legal2", legal2);

      files.forEach((file, index) => {
        formData.set(`file${index + 1}`, file);
      });

      const send = await axios({
        method: "post",
        url: "https://wp.2ginvestment.pl/wp-json/contact-form-7/v1/contact-forms/46/feedback/",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (send.data.status === "validation_failed") {
        console.error(send.data);
        setFileError(true);
        return false;
      }

      setFileError(false);
      setIsSubmit(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        phone: "",
        email: "",
        number: "",
        select_invest: null,
        message: "",
        legal1: false,
        legal2: false,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Imię i nazwisko jest wymagane"),
        phone: Yup.number("Nieprawidłowy numer telefonu")
          .typeError("Nieprawidłowy numer telefonu")
          .required("Numer telefonu jest wymagany"),
        email: Yup.string()
          .email("Nieprawidłowy email")
          .required("E-mail jest wymagany"),
        number: Yup.string().required("Numer mieszania jest wymagany"),
        message: Yup.string().required("Wiadomość jest wymagana"),
        legal1: Yup.bool().oneOf([true], "Pole wymagane"),
      })}
      validateOnBlur={false}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ handleSubmit }) => (
        <form noValidate onSubmit={handleSubmit}>
          <div className="input_group">
            <Input
              type="text"
              name="name"
              placeholder="Imię i nazwisko"
              required
              disabled={is_submit}
            />
            <Input
              type="text"
              name="phone"
              placeholder="Numer telefonu"
              required
              disabled={is_submit}
            />
          </div>
          <div className="input_group">
            <Input
              type="text"
              name="email"
              placeholder="E-mail"
              required
              disabled={is_submit}
            />
            <Input
              type="text"
              name="number"
              placeholder="Nr mieszkania"
              required
              disabled={is_submit}
            />
          </div>
          <Select name="invest" disabled={is_submit} />
          <div className="input_group file">
            <UploadInput
              type="file"
              name="file"
              id="file"
              placeholder="Załącznik"
              value={files}
              onChange={setFiles}
              file_error={file_error}
              setFileError={setFileError}
              disabled={is_submit}
            />
            <Button
              type="file"
              id="file"
              label="Wybierz plik"
              htmlFor="file"
              small
            />
          </div>
          <Input
            type="text"
            name="message"
            placeholder="Wiadomości"
            required
            multiline
            disabled={is_submit}
          />
          <Checkbox
            label={
              "Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych przez 2G Investment Sp. z o.o. NIP: 6292475924, REGON:365195736, KRS: 0000632801, w celu marketingu bezpośredniego dotyczącego własnych produktów i usług. Obowiązek informacyjny zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych (RODO) z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016): informacje o przetwarzaniu przez nas danych, jak również o prawach osób, których dane dotyczą znajdują się w naszej polityce prywatności polityce prywatności"
            }
            name="legal1"
            required
            disabled={is_submit}
          />
          <Checkbox
            label={
              "Wyrażam zgodę na przesyłanie na podany przeze mnie adres poczty elektronicznej informacji handlowych zgodnie z przepisami ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną przyjmując jednocześnie do wiadomości, że niniejsza zgoda może być odwołana w każdym czasie."
            }
            name="legal2"
            disabled={is_submit}
          />
          <span className="required-info">
            <sup>*</sup>pola wymagane
          </span>
          <div className="submit-btn">
            <Button label="Wyślij" type="submit" success={is_submit} />
          </div>
        </form>
      )}
    </Formik>
  );
}

export default ServiceForm;
