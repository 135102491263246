import "./styles.scss";
import React from "react";

const PrivacyPolicyContent = () => {
  return (
    <div className="privacy-policy__content">
      <div className="container small">
        <ol>
          <li className="paragraph-header">Administrator danych</li>
          <ol>
            <li>
              2G Investment Sp. z o.o. ul. Łączna 39, 41-303 Dąbrowa Górnicza,
              śląskie, NIP: 6292475924, KRS: 0000632801, REGON: 365195736.
            </li>
          </ol>
          <li className="paragraph-header">Dane kontaktowe</li>
          <ol>
            <li>
              Z 2G Investment Sp. z o.o. można się skontaktować poprzez adres
              email: biuro@2ginvestment.pl, pod numerem telefonu +48 609 020 161
              lub pisemnie: 2G Investment Sp. z o.o. ul. Łączna 39, 41-303
              Dąbrowa Górnicza, śląskie. 2G Investment Sp. z o.o. nie wyznaczył
              inspektora ochrony danych.
            </li>
          </ol>
          <li className="paragraph-header">
            Cele przetwarzania oraz podstawa prawna przetwarzania
          </li>
          <ul>
            <li>
              Pani/Pana dane będą przetwarzane w celu udzielenia dodatkowych
              informacji handlowych z zakresu oferty 2G Investment Sp. z o.o.
              Podanie danych jest dobrowolne i umożliwia uzyskanie informacji
              handlowej – podstawą prawną przetwarzania danych jest Pani/Pana
              zgoda pod warunkiem, że ta zgoda została przez Panią/Pana
              udzielona, w razie nieudzielenia zgody dane osobowe nie są
              przetwarzane w tym celu.
            </li>
            <li>
              Pani/Pana dane będą przetwarzane w celu zawarcia umowy
              rezerwacyjnej lokalu ze spółką 2G Investment Sp. z o.o., zawarcia
              umowy deweloperskiej lub umowy sprzedaży lokalu ze spółką 2G
              Investment Sp. z o.o., nabycia lokalu w drodze umowy cesji lub na
              rynku pierwotnym i zgłoszenia roszczenia względem spółki 2G
              Investment Sp. z o.o. – podstawą prawną przetwarzania danych jest
              Pani/Pana zgoda pod warunkiem, że ta zgoda została przez
              Panią/Pana udzielona, w razie nieudzielenia zgody dane osobowe nie
              są przetwarzane w tym celu.
            </li>
            <li>
              Pani/Pana dane będą przetwarzane w celu udostępnienia danych
              osobowych przez osobę której dane dotyczą lub osobę lub podmioty
              trzecie w celu otrzymania oferty 2G Investment Sp. z o.o. –
              podstawą prawną przetwarzania danych jest Pani/Pana zgoda pod
              warunkiem, że ta zgoda została przez Panią/Pana udzielona, w razie
              nieudzielenia zgody dane osobowe nie są przetwarzane w tym celu.
            </li>
            <li>
              Pani/Pana dane będą przetwarzane w celu zgłoszenia żądania
              realizacji praw przysługujących na podstawie RODO – podstawą
              prawną przetwarzania danych jest Pani/Pana zgoda pod warunkiem, że
              ta zgoda została przez Panią/Pana udzielona, w razie nieudzielenia
              zgody dane osobowe nie są przetwarzane w tym celu.
            </li>
            <li>
              Pani/Pana dane będą przetwarzane w celu wysłania pytania za
              pośrednictwem formularza kontaktowego znajdującego się stronie
              internetowej 2G Investment Sp. z o.o. – podstawą prawną
              przetwarzania danych jest Pani/Pana zgoda pod warunkiem, że ta
              zgoda została przez Panią/Pana udzielona, w razie nieudzielenia
              zgody dane osobowe nie są przetwarzane w tym celu.
            </li>
            <li>
              Pani/Pana dane będą przetwarzane w celu kontaktu telefonicznego –
              podstawą prawną przetwarzania danych jest Pani/Pana zgoda pod
              warunkiem, że ta zgoda została przez Panią/Pana udzielona, w razie
              nieudzielenia zgody dane osobowe nie są przetwarzane w tym celu.
            </li>
            <li>
              Pani/Pana dane będą przetwarzane w celu marketingu bezpośredniego
              dotyczącego produktów i usług własnych 2G Investment Sp. z o.o. –
              podstawą prawną przetwarzania danych jest Pani/Pana zgoda pod
              warunkiem, że ta zgoda została przez Panią/Pana udzielona, w razie
              nieudzielenia zgody dane osobowe nie są przetwarzane w tym celu.
            </li>
            <li>
              Pani/Pana dane będą przetwarzane w celu realizacji usług takich
              jak np. udział w webinarze, przesłanie newslettera – podstawą
              prawną przetwarzania danych jest Pani/Pana zgoda pod warunkiem, że
              ta zgoda została przez Panią/Pana udzielona, w razie nieudzielenia
              zgody dane osobowe nie są przetwarzane w tym celu.
            </li>
            <li>
              Pani/Pana dane będą przetwarzane w celu przeprowadzenia procesu
              rekrutacji na dane stanowisko – podstawą prawną przetwarzania
              danych jest Pani/Pana zgoda udzielona przy wypełnianiu formularza
              zgłoszeniowego (aplikacja na dane stanowisko).
            </li>
          </ul>
          <li className="paragraph-header">
            Okres, przez który dane będą przechowywane
          </li>
          <ol>
            <li>
              Jeżeli przetwarzanie danych odbywa się na podstawie Pana/Pani
              dobrowolnie udzielonej zgody, to Pana/Pani dane osobowe będą
              przechowywane do czasu wycofania zgody na przetwarzanie danych
              osobowych w konkretnych, wyraźnych i prawnie uzasadnionych celach.
              Zgoda na przetwarzanie danych osobowych może być cofnięta w
              dowolnym momencie. Cofnięcie zgody pozostaje bez wpływu na
              zgodność z prawem przetwarzania, którego dokonano na podstawie
              zgody przed jej cofnięciem.
            </li>
            <li>
              Jeżeli przetwarzanie danych jest niezbędne do wykonania umowy,
              której jest Pan/Pani stroną, lub do podjęcia działań na Pana/Pani
              żądanie, przed zawarciem umowy, to Pana/Pani dane osobowe będą
              przetwarzane przez okres trwania umowy, a po tym okresie przez
              okres przedawnienia ewentualnych roszczeń wynikający z powszechnie
              obowiązujących przepisów prawa.
            </li>
            <li>
              Jeżeli przetwarzanie jest niezbędne do wypełnienia obowiązku
              prawnego ciążącego na Administratorze, to Pana/Pani dane osobowe
              będą przetwarzane przez okres czasu wynikający z powszechnie
              obowiązujących przepisów prawa.
            </li>
            <li>
              Jeżeli przetwarzanie jest niezbędne do celów wynikających z
              prawnie uzasadnionych interesów realizowanych przez Administratora
              lub przez stronę trzecią, to Pana/Pani dane osobowe będą
              przetwarzane przez okres nie dłuższy, niż jest to niezbędne do
              celów, w których dane te są przetwarzane lub do czasu wniesienia
              sprzeciwu wobec przetwarzania danych osobowych w zakresie
              przetwarzania danych osobowych w tych celach, z przyczyn
              związanych z Pana/Pani szczególną sytuacją, chyba że Administrator
              wykaże istnienie ważnych prawnie uzasadnionych podstaw do
              przetwarzania, nadrzędnych wobec Pana/Pani interesów, praw i
              wolności, lub podstaw do ustalenia, dochodzenia lub obrony
              roszczeń.
            </li>
            <li>
              Jeżeli dane osobowe są przetwarzane na potrzeby marketingu
              bezpośredniego, to Pana/Pani dane osobowe będą przetwarzane do
              czasu wniesienia sprzeciwu wobec przetwarzania danych osobowych na
              potrzeby takiego marketingu, w tym profilowania, w zakresie, w
              jakim przetwarzanie jest związane z takim marketingiem
              bezpośrednim.
            </li>
            <li>
              W przypadku przetwarzania danych do celów rekrutacji dane będą
              przetwarzane przez czas trwania rekrutacji, a jeżeli została
              udzielona zgoda na udział w dalszych rekrutacjach przez okres
              dodatkowych 2 lat.
            </li>
          </ol>
          <li className="paragraph-header">Odbiorcy danych</li>
          <ol>
            <li>
              Pani/Pana dane mogą być przekazywane podmiotom przetwarzającym
              dane osobowe na zlecenie 2G Investment Sp. z o.o. w szczególności
              dostawcom usług remontowo-budowlanych i usług IT, biurom
              rachunkowym, organom państwowym i organom wymiaru sprawiedliwości,
              takie jak policja, prokuratura, sądy czy organy regulacyjne,
              kancelariom prawnym i sądom w celu wyegzekwowania lub realizacji
              umowy.
            </li>
            <li>
              Podmioty wyżej wskazane np. dostawcy usług mogą przetwarzać dane
              również na podstawie umowy z 2G Investment Sp. z o.o. i wyłącznie
              zgodnie z poleceniami 2G Investment Sp. z o.o.
            </li>
          </ol>
          <li className="paragraph-header">
            Przekazywanie danych poza Europejski Obszar Gospodarczy
          </li>
          <ol>
            <li>
              Pani/Pana dane nie będą przekazywane do odbiorców znajdujących się
              w państwach poza Europejskim Obszarem Gospodarczym.
            </li>
          </ol>
          <li className="paragraph-header">Prawa osoby, której dane dotyczą</li>
          <ol>
            <li>Przysługuje Pani/Panu prawo:</li>
            <ul>
              <li>wycofania zgody,</li>
              <li>żądania ograniczenia przetwarzania,</li>
              <li>żądania ich usunięcia,</li>
              <li>żądania ich sprostowania,</li>
              <li>dostępu do Pani/Pana danych,</li>
              <li>
                prawo do przenoszenia danych osobowych, tj. do otrzymania od 2G
                Investment Sp. z o.o. Pani/Pana danych osobowych w pliku o
                powszechnie używanym formacie,
              </li>
              <li>
                prawo do przenoszenia danych osobowych, tj. do otrzymania od 2G
                Investment Sp. z o.o. Pani/Pana danych osobowych w pliku o
                powszechnie używanym formacie,
              </li>
            </ul>
            <li>
              W celu realizacji powyżej wymienionych praw należy skontaktować
              się z 2G Investment Sp. z o.o. Można to zrobić np. przesyłając
              wniosek na adres siedziby 2G Investment Sp. z o.o. lub e-mailowo
              na adres biuro@2ginvestment.pl
            </li>
          </ol>
          <li className="paragraph-header">Dobrowolność podania danych</li>
          <ol>
            <li>
              Jeżeli dane osobowe są przetwarzane na podstawie zgody osoby,
              której dane dotyczą, to podanie danych osobowych jest dobrowolne.
              Brak podania danych będzie skutkował brakiem możliwości
              świadczenia usługi, jeżeli zgoda jest warunkiem świadczenia tej
              usługi.
            </li>
            <li>
              Jeżeli dane osobowe są przetwarzane w celach niezbędnych do
              wykonania umowy, której stroną jest osoba, której dane dotyczą,
              lub do podjęcia działań na żądanie osoby, której dane dotyczą,
              przed zawarciem umowy, to podanie danych osobowych jest
              dobrowolne, ale niezbędne do zawarcia umowy z Administratorem.
            </li>
            <li>
              Jeżeli dane osobowe są przetwarzane w celach niezbędnych do
              wykonania umowy, której stroną jest osoba, której dane dotyczą,
              lub do podjęcia działań na żądanie osoby, której dane dotyczą,
              przed zawarciem umowy, to podanie danych osobowych jest
              dobrowolne, ale niezbędne do zawarcia umowy z Administratorem.
            </li>
            <li>
              Jeżeli dane osobowe są przetwarzane do celów wynikających z
              prawnie uzasadnionych interesów realizowanych przez administratora
              lub przez stronę trzecią, to podanie danych osobowych jest
              dobrowolne, ale niezbędne do realizacji tych celów.
            </li>
            <li>
              Podanie danych osobowych w celach marketingowych jest dobrowolne.
              Podanie danych osobowych w celu rozpatrywania ewentualnej
              reklamacji jest dobrowolne, jednak ich niepodanie uniemożliwi
              przyjęcie i rozpatrzenie reklamacji.
            </li>
          </ol>
          <li className="paragraph-header">Podjęte środki ochrony danych</li>
          <ul>
            <li>
              Ochrona danych odbywa się zgodnie z wymogami powszechnie
              obowiązujących przepisów prawa, a ich przechowywanie ma miejsce na
              zabezpieczonych serwerach.
            </li>
            <li>
              Szanujemy prawo do prywatności i dbamy o bezpieczeństwo danych. W
              tym celu używany jest m.in. bezpieczny protokół szyfrowania
              komunikacji (SSL).
            </li>
            <li>
              Wdrożyliśmy szyfrowanie danych oraz mamy wprowadzoną kontrolę
              dostępu dzięki czemu minimalizujemy skutki ewentualnego naruszenia
              bezpieczeństwa danych.
            </li>
          </ul>
          <li className="paragraph-header">Pliki cookies</li>
          <ul>
            <li>
              Witryna moja-klonowa.pl używa cookies. Są to niewielkie pliki
              tekstowe wysyłane przez serwer www i przechowywane przez
              oprogramowanie komputera przeglądarki. Kiedy przeglądarka ponownie
              połączy się ze stroną, witryna rozpoznaje rodzaj urządzenia, z
              którego łączy się użytkownik. Parametry pozwalają na odczytanie
              informacji w nich zawartych jedynie serwerowi, który je utworzył.
              Cookies ułatwiają więc korzystanie z wcześniej odwiedzonych
              witryn. Gromadzone informacje dotyczą adresu IP, typu
              wykorzystywanej przeglądarki, języka, rodzaju systemu
              operacyjnego, dostawcy usług internetowych, informacji o czasie i
              dacie, lokalizacji oraz informacji przesyłanych do witryny za
              pośrednictwem formularza kontaktowego.
            </li>
            <li>
              Zebrane dane służą do monitorowania i sprawdzenia, w jaki sposób
              użytkownicy korzystają z naszych witryn, aby usprawniać
              funkcjonowanie serwisu zapewniając bardziej efektywną i
              bezproblemową nawigację. Monitorowania informacji o użytkownikach
              dokonujemy korzystając z narzędzia Google Analitics, które
              rejestruje zachowanie użytkownika na stronie. Cookies identyfikuje
              użytkownika, co pozwala na dopasowanie treści witryny, z której
              korzysta, do jego potrzeb. Zapamiętując jego preferencje,
              umożliwia odpowiednie dopasowanie skierowanych do niego reklam.
              Stosujemy pliki cookies, aby zagwarantować najwyższy standard
              wygody naszego serwisu, a zebrane dane są wykorzystywane jedynie
              wewnątrz firmy 2G Investment Sp. z o.o. w celu optymalizacji
              działań.
            </li>
            <li>
              Na naszej witrynie wykorzystujemy następujące pliki cookies:
            </li>
            <ul>
              <li>
                „niezbędne” pliki cookies, umożliwiające korzystanie z usług
                dostępnych w ramach serwisu, np. uwierzytelniające pliki cookies
                wykorzystywane do usług wymagających uwierzytelniania w ramach
                serwisu;
              </li>
              <li>
                pliki cookies służące do zapewnienia bezpieczeństwa, np.
                wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania
                w ramach serwisu;
              </li>
              <li>
                „wydajnościowe” pliki cookies, umożliwiające zbieranie
                informacji o sposobie korzystania ze stron internetowych
                serwisu;
              </li>
              <li>
                „reklamowe” pliki cookies, umożliwiające dostarczanie
                użytkownikom treści reklamowych bardziej dostosowanych do ich
                zainteresowań.
              </li>
            </ul>
            <li>
              Użytkownik w każdej chwili ma możliwość wyłączenia lub
              przywrócenia opcji gromadzenia cookies poprzez zmianę ustawień w
              przeglądarce internetowej. Instrukcja zarządzania plikami cookies
              jest dostępna na stronie: {" "}
              <a href="http://www.allaboutcookies.org/manage-cookies">
                http://www.allaboutcookies.org/manage-cookies
              </a>
            </li>
            <li>
              Dodatkowe dane osobowe, jak adres e-mail, zbierane są jedynie w
              miejscach, w których użytkownik wypełniając formularz wyraźnie
              wyraził na to zgodę. Powyższe dane zachowujemy i wykorzystujemy
              tylko do potrzeb niezbędnych do wykonania danej funkcji.
            </li>
          </ul>
        </ol>
      </div>
    </div>
  );
};

export default PrivacyPolicyContent;
