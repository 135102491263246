import "./styles.scss";

import React from "react";
import Carousel from "components/Carousel";

function News({ posts }) {
  return (
    <div className="section-news">
      <div className="container">
        <h2>Aktualności</h2>
        <Carousel items={posts} moreButton />
      </div>
    </div>
  );
}

export default News;
