import * as React from "react";

import Layout from "components/Layout";
import SubpageHero from "components/SubpageHero";
import { About, Value, Plans } from "page_components/about";

const LandPage = () => {
  return (
    <Layout
      seo={{
        title: "O nas",
        description:
          "2G Investment działa na rynku od 2016 roku. Zaczynaliśmy od pozyskiwania hali magazynowych, dziś stale poszerzamy portfolio naszych realizacjiz.",
      }}
    >
      <div className="about-us">
        <SubpageHero pageTitle="O nas" header="Kim jesteśmy?" noExtraPadding />
        <About />
        <Value />
        <Plans />
      </div>
    </Layout>
  );
};

export default LandPage;
