import "./styles.scss";

import React from "react";
import { motion } from "framer-motion";
import { animation } from "utils/animation";

import HexagonItem from "components/HexagonItem";

import HexTransactionIcon from "icons/hex/transaction.svg";
import HexSearchIcon from "icons/hex/search.svg";
import HexWaterIcon from "icons/hex/water.svg";
import HexEcoIcon from "icons/hex/eco.svg";

const how_data = [
  {
    image: <HexTransactionIcon />,
    normalText: "Ustalamy najlepszą opcję przyszłej transakcji",
  },
  {
    image: <HexSearchIcon />,
    normalText:
      "Sprawdzamy potencjał gruntów pod kątem inwestycji deweloperskiej",
  },
  {
    image: <HexWaterIcon />,
    normalText:
      "Sprawdzamy możliwości rozwoju mediów i infrastruktury drogowej w pobliżu nieruchomości",
  },
  {
    image: <HexEcoIcon />,
    normalText: "Analizujemy wpływ inwestycji na środowisko",
  },
];

function How() {
  return (
    <div className="how-section">
      <div className="container">
        <motion.h2 className="center" {...animation.top}>
          Jak wygląda proces sprzedażowy?
        </motion.h2>
        <div className="items">
          {how_data.map((data, index) => (
            <HexagonItem key={index} {...data} connection />
          ))}
        </div>
      </div>
    </div>
  );
}

export default How;
