import * as React from "react";
import Form from "components/Form";
import SubpageHero from "components/SubpageHero";
import Layout from "components/Layout";
import How from "page_components/service/How";
import HeroLeft from "images/service/hero_left.png";
import HeroRight from "images/service/hero_right.png";

const ServicePage = () => {
  return (
    <Layout seo={{ title: "Serwis" }}>
      <div className="service">
        <SubpageHero
          pageTitle="Serwis"
          header="W mieszkaniu zdarzyła się usterka i potrzebujesz pomocy?"
          hint="Skorzystaj z naszego serwisu dla mieszkańców"
          imageLeft={HeroLeft}
          imageRight={HeroRight}
        />
        <How />
        <Form header="Kontakt z serwisem" inputVariant={3} sideVariant={2} />
      </div>
    </Layout>
  );
};

export default ServicePage;
